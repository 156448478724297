import _ from 'lodash'
import { generate, createSagaAPICall } from '../generator'

export const ACTIONS = {
  FOLDERS_FETCH_REQUEST: 'FILE_FOLDERS_FETCH_REQUEST',
  FOLDERS_FETCH_SUCCESS: 'FILE_FOLDERS_FETCH_SUCCESS',
  FOLDERS_FETCH_FAILURE: 'FILE_FOLDERS_FETCH_FAILURE',
  MODEL_FETCH_REQUEST: 'FILE_MODEL_FETCH_REQUEST',
  MODEL_FETCH_SUCCESS: 'FILE_MODEL_FETCH_SUCCESS',
  MODEL_FETCH_FAILURE: 'FILE_MODEL_FETCH_FAILURE',
  FOLDERS_NEW: 'FOLDERS_NEW',
}

const reducerActions = {
  [ACTIONS.FOLDERS_NEW]: (state, action) => {
    return {
      ...state,
      folders: [...state.folders, action.folder],
    }
  },
  [ACTIONS.FOLDERS_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      folders: state.folder ? state.folder : [],
    }
  },
  [ACTIONS.FOLDERS_FETCH_SUCCESS]: (state, action) => {
    return {
      ...state,
      folders: action.payload.data,
    }
  },
  [ACTIONS.FOLDERS_FETCH_FAILURE]: (state) => {
    return state
  },
  [ACTIONS.MODEL_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      // folders: state.folder ? state.folder : [],
    }
  },
  [ACTIONS.MODEL_FETCH_SUCCESS]: (state, action) => {
    const { folder } = action.data.data
    const splittedFolder = folder.split('/')
    const classType = splittedFolder[2]
    const classId = parseInt(splittedFolder[3], 10)
    const newState = Object.assign({}, state.byClassbyId)

    newState[classType] = newState[classType] || {}
    newState[classType][classId] = _.uniqBy(
      // removing duplicates
      [...(newState[classType][classId] || []), ...action.payload.data].sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
      ), // sorting to not remove the newest ones
      'id',
    )

    return {
      ...state,
      byClassbyId: newState,
    }
  },
  [ACTIONS.MODEL_FETCH_FAILURE]: (state) => {
    return state
  },
}

const restCreators = {
  fetchFolders: () => {
    return {
      type: ACTIONS.FOLDERS_FETCH_REQUEST,
    }
  },
  addFolder: (folder) => {
    return {
      type: ACTIONS.FOLDERS_NEW,
      folder: `/${folder}`,
    }
  },
  fetchModelFolders: (model, id, subfolder) => {
    return {
      type: ACTIONS.MODEL_FETCH_REQUEST,
      data: {
        folder: `/models/${model}/${id}${subfolder ? `/${subfolder}` : ''}`,
        per_page: 0,
      },
    }
  },
}

const all = generate('FILE', 'files', {
  reducerActions,
  creators: restCreators,
  sagas: [
    createSagaAPICall('FILE_FOLDERS_FETCH', 'files/folders'),
    createSagaAPICall('FILE_MODEL_FETCH', 'files'),
  ],
})

const { saga, reducer, actions, creators } = all

export { saga, reducer, actions, creators }

export default {
  saga: all.saga,
  reducer: all.reducer,
  actions: Object.assign({}, all.actionNames, ACTIONS),
  creators: all.action,
}
