import actions from './actions'

const initialState = {
  isMobileView: false,
  isMobileMenuOpen: false,
  isLightTheme: true,
  isSettingsOpen: true,
  isMenuTop: true,
  isMenuCollapsed: false,
  isBorderless: true,
  isSquaredBorders: false,
  isFixedWidth: false,
  isMenuShadow: true,
  locale: 'en-US',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
