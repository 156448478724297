import * as ACTION from './actions'

export const INIT_STATE = {}

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_REQUEST:
      return state

    case ACTION.FETCH_SUCCESS:
      return Object.assign({}, state, action.payload)

    case ACTION.FETCH_FAILURE:
      return state

    default:
      return state
  }
}
