import { getURLbyEnvironment, getEnvironmentName } from './helpers'

const LOCAL_API_URL = 'http://localhost/api/v1/'
const BRANCHES_API_URLS = {
  production: 'https://lw-live-api.qunabu.com/api/',
  dev: 'https://lw-database-backend.stage.etd24.pl/api/',
}

const getURL = () => {
  if (localStorage.getItem('API_URL') && !process.env.LOCAL_API) {
    return localStorage.getItem('API_URL')
  }

  return process.env.LOCAL_API
    ? LOCAL_API_URL
    : getURLbyEnvironment(BRANCHES_API_URLS, getEnvironmentName())
}

const queryString = require('query-string')

// const URL = '/api/v1/';

/* eslint no-underscore-dangle: 0 */

const API = {
  /** key value data */
  abortSignals: {
    GET: {},
  },

  /** sets redux store to API  */
  set store(value) {
    this._store = value
  },

  /** get redux store to API  */
  get store() {
    return this._store
  },

  /** sets API main url (prefix) */
  set url(value) {
    this._url = value
  },

  /** get API main url (prefix) */
  get url() {
    return this._url ? this._url : getURL()
  },

  /** sets API token */
  set token(value) {
    this._token = value
  },

  /** gets API token */
  get token() {
    return this._token
  },

  /** get json API headers */
  get headers() {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }

    if (this.store) {
      const state = this.store.getState()
      if (state.user.token) {
        headers.Authorization = `Bearer ${state.user.token}`
      }
    }

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`
    }

    return headers
  },

  /** get upload API headers */
  get uploadHeaders() {
    /* eslint prefer-destructuring: ["error", {AssignmentExpression: {array: true}}] */
    let token = this.token
    if (this.store) {
      const state = this.store.getState()

      if (state.user.token) {
        token = `Bearer ${state.user.token}`
      }
    }

    return token
      ? {
          Authorization: token ? `${token}` : 'Bearer abcdef64',
        }
      : {}
  },

  /** get full endpoint path */
  getpath(endpoint) {
    return `${getURL()}${endpoint}`
  },

  /** call and API and returns a Promise */
  call(endpoint, method = 'GET', data = null, formData = null) {
    const controller = new AbortController()

    const fetchOpt = {
      method,
      headers: API.headers,
      signal: controller.signal,
    }

    if (method !== 'GET' && data) {
      /** remove empty values from the body  */
      /* eslint no-restricted-syntax: 0 */
      for (const key in data) {
        if (data[key] === undefined || data[key] === null) {
          // if ((!data[key] || data[key] === '') && data[key] !== 0) {
          delete data[key]
        }
      }

      fetchOpt.body = JSON.stringify(data)
    }

    if (method === 'GET' && data) {
      /** remove empty values from the query  */
      /* eslint no-restricted-syntax: 0 */
      for (const key in data) {
        if ((!data[key] || data[key] === '') && data[key] !== 0) {
          delete data[key]
        }
      }

      /* eslint  prefer-template: 0 */
      const sign = endpoint.includes('?') ? '&' : '?'
      endpoint += sign + queryString.stringify(data, { arrayFormat: 'bracket' })
    }

    /** check if any of objects is file. If so, rewrite this to FormData */
    if (data && formData === null && Object.values(data).some((value) => value instanceof File)) {
      formData = new FormData()
      Object.keys(data).forEach((key) => {
        formData.set(key, data[key])
      })

      console.log('formdata', formData)
    }

    if (formData) {
      fetchOpt.body = formData
      fetchOpt.headers['Content-Type'] = 'multipart/form-data'
      delete fetchOpt.headers['Content-Type']
    }

    if (API.abortSignals[method]) {
      if (API.abortSignals[method][endpoint]) {
        // aborting previous GET call
        API.abortSignals[method][endpoint].abort()
      }
      API.abortSignals[method][endpoint] = controller
    }

    return fetch(getURL() + endpoint, fetchOpt)
  },
}

export default API
