export const getURLbyEnvironment = (URLS, gitBranch = 'develop') => {
  const answerKey = Object.keys(URLS).find((key) => gitBranch.includes(key))
  return answerKey ? URLS[answerKey] : URLS.develop
}

export const getEnvironmentName = () => {
  switch (window.location.hostname) {
    case 'lw-live.qunabu.com':
      return 'production'
    default:
    case 'localhost':
    case 'lw.qunabu.com':
      return 'dev'
  }
}

export default {
  getURLbyEnvironment,
  getEnvironmentName,
}
