import React, { Component } from 'react'
import '../scss/app.scss'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

// import { fetch } from "./redux/data/actions";
import IndexLayout from './layouts'
import Router from './router'
import API from './services/api'
import { loadCurrentAccount } from './redux/user/actions'
import { fetchURLsFromLocalStorage } from './services/initApiLocalstorate'

class App extends Component {
  componentWillMount() {
    // this.props.dispatch(fetch());
  }

  componentDidMount() {
    if (this.props.user.token) {
      API.token = this.props.user.token
      this.props.dispatch(loadCurrentAccount())
    }
    fetchURLsFromLocalStorage()
  }

  render() {
    return (
      <IndexLayout>
        <Router />
      </IndexLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.Data,
  user: state.user,
})

export default withRouter(connect(mapStateToProps)(App))
