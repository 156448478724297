/**
 * <Tag color=""></Tag>
 <Tag color="red"></Tag>
 <Tag color="volcano"></Tag>
 <Tag color="orange"></Tag>
 <Tag color="gold"></Tag>
 <Tag color="lime"></Tag>
 <Tag color="green"></Tag>
 <Tag color="cyan"></Tag>
 <Tag color="blue">blue</Tag>
 <Tag color="geekblue">geekblue</Tag>
 <Tag color="purple">purple</Tag>
 */
export const MODELS = [
  {
    key: 'Email',
    name: 'Email',
    nameKey: 'subject',
    tagColor: 'orange',
    url: '/emails',
    classType: 'Email',
  },
  {
    key: 'Event',
    name: 'Task',
    nameKey: 'title',
    tagColor: 'orange',
    url: '/tasks',
    classType: 'Event',
  },
  {
    key: 'User',
    name: 'User',
    nameKey: 'name',
    tagColor: 'orange',
    url: '/users',
    classType: 'User',
  },
  {
    key: 'Funder',
    name: 'Funder',
    nameKey: 'name',
    tagColor: 'orange',
    url: '/funders/list',
    classType: 'Funder',
  },
  {
    key: 'Borrower',
    name: 'Borrower',
    nameKey: 'name',
    typeColumn: 'entity_type',
    tagColor: 'orange',
    url: '/borrowers/list',
    classType: 'Corporate',
    params: {
      entity_type: 'Borrower',
    },
  },
  {
    key: 'Person',
    name: 'Person',
    nameKey: 'name',
    typeColumn: 'entity_type', //
    tagColor: 'orange',
    url: '/cms/people',
    classType: 'Corporate',
    params: {
      entity_type: 'Person', // pointless
    },
  },
  {
    key: 'Broker',
    name: 'Broker',
    nameKey: 'name',
    typeColumn: 'entity_type', //
    tagColor: 'magenta',
    url: '/cms/brokers',
    classType: 'Corporate',
    params: {
      entity_type: 'Broker',
    },
  },
  {
    key: 'LegalFirm',
    name: 'Legal Firm',
    nameKey: 'name',
    tagColor: 'volcano',
    typeColumn: 'entity_type', //
    url: '/cms/legal_firms',
    classType: 'Corporate',
    params: {
      entity_type: 'LegalFirm',
    },
  },
  {
    key: 'FunderIntroducer',
    name: 'Funder Introducer',
    nameKey: 'name',
    tagColor: 'lime',
    typeColumn: 'entity_type', //
    url: '/cms/funder_introducers',
    classType: 'Corporate',
    params: {
      entity_type: 'FunderIntroducer',
    },
  },
  {
    key: 'LendingPlatform',
    name: 'Lending Platform',
    nameKey: 'name',
    tagColor: 'blue',
    typeColumn: 'entity_type', //
    url: '/cms/lending_platforms',
    classType: 'Corporate',
    params: {
      entity_type: 'LendingPlatform',
    },
  },
  {
    key: 'ValuationFirm',
    name: 'Valuation Firm',
    nameKey: 'name',
    tagColor: 'green',
    typeColumn: 'entity_type', //
    url: '/cms/valuation_firms',
    classType: 'Corporate',
    params: {
      entity_type: 'ValuationFirm',
    },
  },
  {
    key: 'ProjectMonitor',
    name: 'Project Monitor',
    nameKey: 'name',
    tagColor: 'geekblue',
    typeColumn: 'entity_type', //
    url: '/cms/project_monitors',
    label: 'Project Monitor',
    classType: 'Corporate',
    params: {
      entity_type: 'ProjectMonitor',
    },
  },
  {
    key: 'ConstructionCompany',
    name: 'Construction Company',
    nameKey: 'name',
    tagColor: 'cyan',
    typeColumn: 'entity_type', //
    url: '/cms/construction_companies',
    classType: 'Corporate',
    params: {
      entity_type: 'ConstructionCompany',
    },
  },
  {
    key: 'Insurer',
    name: 'Insurer',
    nameKey: 'name',
    tagColor: 'red',
    url: '/cms/insurers',
    typeColumn: 'entity_type', //
    classType: 'Corporate',
    params: {
      entity_type: 'Insurer',
    },
  },
  {
    key: 'LW_SPV',
    name: 'LW SPV',
    nameKey: 'name',
    tagColor: 'purple',
    url: '/cms/lw_spv',
    label: 'LW SPV',
    classType: 'Corporate',
    typeColumn: 'entity_type',
    params: {
      entity_type: 'LW_SPV',
    },
  },
  {
    key: 'Property',
    name: 'Property',
    nameKey: 'land_registry_number',
    tagColor: 'gold',
    url: '/properties',
  },
  {
    key: 'File',
    name: 'File',
    nameKey: 'title',
    tagColor: 'orange',
    url: 'dms/file',
    classType: 'File',
  },
  {
    key: 'Shareholder',
    name: 'Shareholder',
    nameKey: 'name',
    tagColor: 'purple',
    url: '/cms/shareholders',
    label: 'LW SPV',
    classType: 'Corporate',
    typeColumn: 'entity_type',
    params: {
      entity_type: 'Shareholder',
    },
  },
  {
    key: 'Loan',
    name: 'Loan',
    nameKey: 'name',
    tagColor: 'firebrick',
    url: '/loans/list',
    classType: 'Loan',
  },
  {
    key: 'Corporate',
    name: 'Corporation',
    classType: 'Corporate',
  },
]

const INSTRUCTIONS = {
  GrouppedFiles:
    'This component is used for .... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel feugiat odio. Vivamus elit nibh, euismod id commodo ac, dignissim quis lacus. Morbi mollis maximus est in sodales',
}

export default {
  MODELS,
  INSTRUCTIONS,
}
