import API from '../api'
import { MODELS } from '../../config'

export function getApiData(bondKey, query, options) {
  const { customFilter } = options
  const classObj = MODELS.find((c) => c.key === bondKey)

  const apiData = {
    query,
    class_type: classObj.classType,
    ...customFilter,
  }

  if (classObj.typeColumn) {
    apiData.type_column = classObj.typeColumn
    apiData.type_value = classObj.key
  }

  return apiData
}

export async function getBonds(bondKey, query, options) {
  const response = await API.call('bonds/autocomplete', 'GET', getApiData(bondKey, query, options))
  if (response && response.ok) {
    return response.json()
  }
  return Promise.reject(response)
}

export default {
  getApiData,
  getBonds,
}
