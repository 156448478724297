import { call, put, takeLatest } from 'redux-saga/effects'
import API from '../../services/api'
import * as ACTION from './actions'

export function createSaga(prefix, endpoint = null, method = 'GET') {
  return function* saga(action) {
    const data = Object.assign({}, action)

    if (action.id && endpoint.indexOf('{id}') !== -1) {
      endpoint = endpoint.split('{id}').join(action.id)
    }

    try {
      const response = yield call(API.call, endpoint, method, action.data)
      const responseBody = yield call([response, 'json'])

      if (response.status >= 400) {
        yield put({
          type: `${prefix}_FAILURE`,
          data,
          errorCode: response.status,
          payload: responseBody,
        })
      } else {
        yield put({ type: `${prefix}_SUCCESS`, payload: responseBody })
      }
    } catch (e) {
      yield put({ type: `${prefix}_FAILURE`, message: e.message })
    }
  }
}

function* mySaga() {
  yield takeLatest(ACTION.FETCH_REQUEST, createSaga('FETCH', 'data'))
}

export default mySaga
