import React, { Component } from 'react'
import Select from 'antd/lib/select'
import PropTypes from 'prop-types'

import API from '../../services/api'
import './hasmany.scss'

const { Option } = Select

const classes = ['Corporate', 'Insurer', 'LegalFirm', 'Person', 'ValuationFirm', 'MarketingGroup']

class HasMany extends Component {
  static propTypes = {
    /** array of default tags for autocomplete */
    onChange: PropTypes.func,
    classType: PropTypes.oneOf(classes),
    initialValues: PropTypes.array,
  }

  static defaultProps = {
    onChange: () => {},
    classType: null,
    initialValues: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      options: [],
      values: props.initialValues
        ? props.initialValues.map((value) => (Number.isInteger(value) ? value : value.id))
        : [],
    }
  }

  componentDidMount() {
    if (this.props.classType) {
      this.callAPI()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.classType !== this.props.classType) {
      this.callAPI()
    }
    if (prevProps.initialValues !== this.props.initialValues) {
      this.setState({
        values: this.props.initialValues.map((value) =>
          Number.isInteger(value) ? value : value.id,
        ),
      })
    }
  }

  onChange = (values) => {
    this.setState({ values })
    this.props.onChange(values)
  }

  callAPI() {
    if (this.props.classType) {
      this.setState({
        loading: true,
      })
      try {
        API.call('bonds/autocomplete', 'GET', {
          query: '*',
          class_type: this.props.classType,
        })
          .catch((er) => console.log(er))
          .then((response) => {
            if (response && response.ok) {
              return response.json()
            }
            return false
            // throw new Error('Something went wrong')
          })
          .then((data) => {
            if (data) {
              this.setState({
                options: data.data,
                loading: false,
              })
            }
          })
      } catch (er) {
        // this
      }
    }
  }

  render() {
    return (
      <div className="has-many">
        <Select
          className="has-many__field"
          mode="multiple"
          showSearch
          loading={this.state.loading}
          onChange={this.onChange}
          value={this.state.values}
          optionFilterProp="name"
          placeholder="String typing to search"
        >
          {this.state.options.map((o) => (
            <Option key={o.id} value={o.id} name={o.name}>
              {o.name}
            </Option>
          ))}
        </Select>
      </div>
    )
  }
}

export default HasMany
