import { createSagaAPICall } from './generator'

export const generateGroup = (prefix, url) => {
  const ACTIONS = {
    GROUP_ADD_REQUEST: `${prefix}_GROUP_ADD_REQUEST`,
    GROUP_ADD_SUCCESS: `${prefix}_GROUP_ADD_SUCCESS`,
    GROUP_ADD_FAILURE: `${prefix}_GROUP_ADD_FAILURE`,
    GROUP_EDIT_REQUEST: `${prefix}_GROUP_EDIT_REQUEST`,
    GROUP_EDIT_SUCCESS: `${prefix}_GROUP_EDIT_SUCCESS`,
    GROUP_EDIT_FAILURE: `${prefix}_GROUP_EDIT_FAILURE`,
    GROUP_DELETE_REQUEST: `${prefix}_GROUP_DELETE_REQUEST`,
    GROUP_DELETE_SUCCESS: `${prefix}_GROUP_DELETE_SUCCESS`,
    GROUP_DELETE_FAILURE: `${prefix}_GROUP_DELETE_FAILURE`,
  }

  const reducerActions = {
    [ACTIONS.GROUP_ADD_REQUEST]: (state) => ({ ...state }),
    [ACTIONS.GROUP_EDIT_REQUEST]: (state) => ({ ...state }),
    [ACTIONS.GROUP_DELETE_REQUEST]: (state) => ({ ...state }),

    [ACTIONS.GROUP_ADD_FAILURE]: (state) => ({ ...state }),
    [ACTIONS.GROUP_EDIT_FAILURE]: (state) => ({ ...state }),
    [ACTIONS.GROUP_DELETE_FAILURE]: (state) => ({ ...state }),

    [ACTIONS.GROUP_ADD_SUCCESS]: (state, action) => {
      const newStateData = [...state.data, ...action.payload.data]
      return {
        ...state,
        data: newStateData,
      }
    },
    [ACTIONS.GROUP_EDIT_SUCCESS]: (state, action) => {
      const itemsToReplace = action.payload.data
      const newStateData = state.data.map((old) => {
        const newItem = itemsToReplace.find((item) => item.id === old.id)
        return newItem || old
      })
      return {
        ...state,
        data: newStateData,
      }
    },
    [ACTIONS.GROUP_DELETE_SUCCESS]: (state, action) => {
      const newStateData = state.data.filter(
        (el) => !action.data.data.ids.find((id) => id === el.id),
      )
      return {
        ...state,
        data: newStateData,
      }
    },
  }

  const restCreators = {
    addGroup: (data) => {
      return {
        type: ACTIONS.GROUP_ADD_REQUEST,
        data,
      }
    },
    editGroup: (data) => {
      return {
        type: ACTIONS.GROUP_EDIT_REQUEST,
        data,
      }
    },
    deleteGroup: (data) => {
      return {
        type: ACTIONS.GROUP_DELETE_REQUEST,
        data: { ids: data },
      }
    },
  }

  const sagas = [
    createSagaAPICall(`${prefix}_GROUP_ADD`, `${url}/group`, 'POST'),
    createSagaAPICall(`${prefix}_GROUP_EDIT`, `${url}/group`, 'PUT'),
    createSagaAPICall(`${prefix}_GROUP_DELETE`, `${url}/group`, 'DELETE'),
  ]

  return {
    ACTIONS,
    reducerActions,
    restCreators,
    sagas,
  }
}

export default {
  generateGroup,
}
