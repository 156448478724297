export const FETCH_REQUEST = 'TAGS_FETCH_REQUEST'
export const FETCH_SUCCESS = 'TAGS_FETCH_SUCCESS'
export const FETCH_FAILURE = 'TAGS_FETCH_FAILURE'

export const FETCH_TITLES_REQUEST = 'TAGS_FETCH_TITLES_REQUEST'
export const FETCH_TITLES_SUCCESS = 'TAGS_FETCH_TITLES_SUCCESS'
export const FETCH_TITLES_FAILURE = 'TAGS_FETCH_TITLES_FAILURE'

export const ADD_REQUEST = 'TAGS_ADD_REQUEST'
export const ADD_SUCCESS = 'TAGS_ADD_SUCCESS'
export const ADD_FAILURE = 'TAGS_ADD_FAILURE'

export const REMOVE_REQUEST = 'TAGS_REMOVE_REQUEST'
export const REMOVE_SUCCESS = 'TAGS_REMOVE_SUCCESS'
export const REMOVE_FAILURE = 'TAGS_REMOVE_FAILURE'

export const fetchTitles = () => {
  return {
    type: FETCH_TITLES_REQUEST,
  }
}

export const fetch = (query) => {
  return {
    type: FETCH_REQUEST,
    query,
  }
}

export const add = (data) => {
  return {
    type: ADD_REQUEST,
    data,
  }
}

export const remove = (id, data) => {
  return {
    type: REMOVE_REQUEST,
    id,
    data,
  }
}

/*
export const get = id => {
  return {
    type: GET_REQUEST,
    id
  };
};
*/
