import { generate } from '../generator'
import { generateGroup } from '../groupGenerator'

const { reducerActions, restCreators, sagas } = generateGroup('LOAN_FEES', 'loan_fees')

const all = generate('LOAN_FEES', 'loan_fees', {
  paginated: false,
  reducerActions,
  creators: restCreators,
  sagas,
})
const { saga, reducer, actions, creators } = all

export { saga, reducer, actions, creators }

export default {
  saga: all.saga,
  reducer: all.reducer,
  actions: all.actionNames,
  creators: all.action,
}
