import * as ACTION from './actions'

export const INIT_STATE = {
  loading: false,
  error: false,
  paginatedData: {
    current_page: 0,
    last_page: 0,
    per_page: 10,
    to: 0,
    total: 0,
    data: [],
  },
  byId: {},
}

export default function reducer(state = INIT_STATE, action) {
  if (state === undefined) {
    return INIT_STATE
  }
  switch (action.type) {
    // FETCH -- start
    case ACTION.FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case ACTION.FETCH_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        paginatedData: action.payload.data,
      }

    case ACTION.FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // FETCH -- end

    // GET -- start
    case ACTION.GET_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        byId: {
          ...state.byId,
          [action.id]: state.byId[action.id]
            ? { ...state.byId[action.id], loading: true, error: false }
            : { loading: true, error: false },
        },
      }

    case ACTION.GET_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        byId: {
          ...state.byId,
          [action.payload.data.id]: {
            ...state.byId[action.payload.data.id],
            ...action.payload.data,
            loading: false,
            error: false,
          },
        },
      }

    case ACTION.GET_FAILURE:
      return {
        ...state,
        loading: false,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            loading: false,
            error: action.payload.data,
          },
        },
      }

    // GET -- end

    default:
      return state
  }
}
