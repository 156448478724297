export const SET_STATE = 'USER_SET_STATE'
export const LOGIN = 'USER_LOGIN'
export const LOAD_CURRENT_ACCOUNT = 'USER_LOAD_CURRENT_ACCOUNT'
export const LOGOUT = 'USER_LOGOUT'

export const setState = (payload) => ({
  type: SET_STATE,
  payload,
})

export const login = (payload) => ({
  type: LOGIN,
  payload,
})

export const loadCurrentAccount = () => ({
  type: LOAD_CURRENT_ACCOUNT,
})

export const logout = () => ({
  type: LOGOUT,
})

export default {
  SET_STATE,
  LOGIN,
  LOAD_CURRENT_ACCOUNT,
  LOGOUT,
  login,
  loadCurrentAccount,
  logout,
}
