import { generate } from '../generator'

const reducerActions = {
  CHANGES_FETCH_SUCCESS: (state, action) => {
    const newState = {} // Previous objects merging let changes from others entity to stay in redux
    action.payload.data.forEach((change) => {
      newState[change.auditable_type] = newState[change.auditable_type] || {}
      newState[change.auditable_type][change.auditable_id] =
        newState[change.auditable_type][change.auditable_id] || []
      newState[change.auditable_type][change.auditable_id][change.id] = change
    })
    return {
      ...state,
      loading: false,
      error: false,
      byClassbyId: newState,
    }
  },
}

const all = generate('CHANGES', 'changes', {
  reducerActions,
})

const { saga, reducer, actions, creators } = all

export { saga, reducer, actions, creators }

export default {
  saga: all.saga,
  reducer: all.reducer,
  actions: all.actionNames,
  creators: all.action,
}
