export const FETCH_REQUEST = 'BONDS_FETCH_REQUEST'
export const FETCH_SUCCESS = 'BONDS_FETCH_SUCCESS'
export const FETCH_FAILURE = 'BONDS_FETCH_FAILURE'

export const ADD_REQUEST = 'BONDS_ADD_REQUEST'
export const ADD_SUCCESS = 'BONDS_ADD_SUCCESS'
export const ADD_FAILURE = 'BONDS_ADD_FAILURE'

export const REMOVE_REQUEST = 'BONDS_REMOVE_REQUEST'
export const REMOVE_SUCCESS = 'BONDS_REMOVE_SUCCESS'
export const REMOVE_FAILURE = 'BONDS_REMOVE_FAILURE'

export const fetch = (query = {}) => {
  return {
    type: FETCH_REQUEST,
    query,
  }
}

export const add = (data) => {
  return {
    type: ADD_REQUEST,
    data,
  }
}

export const remove = (id, data) => {
  return {
    type: REMOVE_REQUEST,
    id,
    data,
  }
}
