import React from 'react'
import styles from './style.module.scss'
import { getVersion } from '../../../utils/utils'

const FOOTER_TEXT = `LW Lending Platform | ${getVersion()}`

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.inneralt}>{FOOTER_TEXT}</div>
    {/* <div className={styles.inner}>
      <div className="row">
        <div className="col-lg-9">
          <p>
            <strong>bla bla bla </strong>
          </p>
          <p>
            bla bla
          </p>

        </div>
      </div>
    </div> */}
  </div>
)

export default Footer
