import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
// import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from './components/LayoutComponents/Loader'
import NotFoundPage from './pages/404'

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // System Pages
  {
    component: loadable(() => import('./pages/users/index')),
    path: ['/users', '/users/:id'],
    exact: true,
  },
  {
    path: '/user/login',
    component: loadable(() => import('./pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('./pages/user/forgot')),
    exact: true,
  },
  {
    path: '/dashboard',
    component: loadable(() => import('./pages/dashboard/index')),
    exact: true,
  },
  {
    component: loadable(() => import('./pages/pipeline/index')),
    path: ['/pipeline/list', '/pipeline/list/:id'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/pipeline/dashboard')),
    path: '/pipeline/dashboard',
    exact: true,
  },
  {
    component: loadable(() => import('./pages/loans/index')),
    path: ['/loans/list', '/loans/list/:id'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/loans/dashboard')),
    path: '/loans/dashboard',
    exact: true,
  },
  {
    component: loadable(() => import('./pages/loans/simulate')),
    path: '/loans/simulate',
    exact: true,
  },
  {
    component: loadable(() => import('./pages/info/definitions')),
    path: '/info/definitions',
    exact: true,
  },
  {
    component: loadable(() => import('./pages/borrowers/index')),
    path: ['/borrowers/list', '/borrowers/list/:id'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/borrowers/dashboard')),
    path: ['/borrowers/dashboard'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/funders/dashboard')),
    path: ['/funders/dashboard'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/funders/index')),
    path: ['/funders/list', '/funders/list/:id'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/reports/index')),
    path: '/reports',
  },
  {
    component: loadable(() => import('./pages/tasks/index')),
    path: '/tasks',
    exact: true,
  },
  {
    component: loadable(() => import('./pages/tasks/details')),
    path: '/tasks/:id',
    exact: true,
  },
  {
    component: loadable(() => import('./pages/emails/index')),
    path: '/emails',
    exact: true,
  },
  {
    component: loadable(() => import('./pages/emails/details')),
    path: '/emails/:id',
  },
  {
    component: loadable(() => import('./pages/dms/index')),
    path: '/dms',
    exact: true,
  },
  {
    component: loadable(() => import('./pages/lwfee/index')),
    path: '/lwfee',
    exact: true,
  },
  {
    component: loadable(() => import('./pages/dms/file')),
    path: '/dms/file/:id',
    exact: true,
  },
  {
    component: loadable(() => import('./pages/cms/index')),
    path: '/cms',
    exact: true,
  },
  {
    component: loadable(() => import('./pages/cms/people')),
    path: ['/cms/people', '/cms/people/:id'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/cms/marketing')),
    path: ['/cms/marketing'],
    exact: true,
  },

  {
    component: loadable(() => import('./pages/cms/funder_introducers')),
    path: ['/cms/funder_introducers', '/cms/funder_introducers/:id'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/cms/lending_platforms')),
    path: ['/cms/lending_platforms', '/cms/lending_platforms/:id'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/cms/brokers')),
    path: ['/cms/brokers', '/cms/brokers/:id'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/cms/legal_firms')),
    path: ['/cms/legal_firms', '/cms/legal_firms/:id'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/cms/valuation_firms')),
    path: ['/cms/valuation_firms', '/cms/valuation_firms/:id'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/cms/project_monitors')),
    path: ['/cms/project_monitors', '/cms/project_monitors/:id'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/cms/construction_companies')),
    path: ['/cms/construction_companies', '/cms/construction_companies/:id'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/cms/insurers')),
    path: ['/cms/insurers', '/cms/insurers/:id'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/cms/lw_spv')),
    path: ['/cms/lw_spv', '/cms/lw_spv/:id'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/cms/marketing_groups')),
    path: ['/cms/marketing_groups', '/cms/marketing_groups/:id'],
    exact: true,
  },
  {
    component: loadable(() => import('./pages/cms/shareholders')),
    path: ['/cms/shareholders', '/cms/shareholders/:id'],
    exact: true,
  },
]

class Router extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        {routes.map((route) => (
          <Route
            path={route.path}
            component={route.component}
            key={route.path}
            exact={route.exact}
          />
        ))}
        <Route component={NotFoundPage} />
      </Switch>
    )
  }
}

export default Router
