import { generate } from '../generator'

const reducerActions = {
  NOTES_FETCH_SUCCESS: (state, action) => {
    const newState = Object.assign({}, state.byClassbyId)
    action.payload.data.data.forEach((note) => {
      newState[note.class_type] = newState[note.class_type] || {}
      newState[note.class_type][note.class_id] = newState[note.class_type][note.class_id] || []
      newState[note.class_type][note.class_id][note.id] = note
    })
    return {
      ...state,
      loading: false,
      error: false,
      byClassbyId: newState,
    }
  },
  NOTES_ADD_SUCCESS: (state, action) => {
    const newState = Object.assign({}, state.byClassbyId)
    const classType = action.payload.data.class_type
    const classId = action.payload.data.class_id

    newState[classType] = newState[classType] || {}
    newState[classType][classId] = newState[classType][classId] || []
    newState[classType][classId][action.payload.data.id] = action.payload.data

    return {
      ...state,
      loading: false,
      error: false,
      byClassbyId: newState,
    }
  },
}

const all = generate('NOTES', 'notes', {
  reducerActions,
})
const { saga, reducer, actions, creators } = all

export { saga, reducer, actions, creators }

export default {
  saga: all.saga,
  reducer: all.reducer,
  actions: all.actionNames,
  creators: all.action,
}
