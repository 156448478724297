import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'

import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { HashRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import * as serviceWorker from './serviceWorker'
import { store, history, persistor } from './redux/store'
import API from './services/api'
import { CALC_DATE_FORMAT } from './config/dates'

import './debug'
import './sentry'

API.store = store

// eslint-disable-next-line func-names
moment.fn.toJSON = function() {
  return this.format(CALC_DATE_FORMAT)
}

// import Grid from "ss-grid";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// this is for test only
// localStorage.setItem('token', '}*624aY2wQDU96=8N),9');

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <HashRouter>
          <App />
        </HashRouter>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.querySelector('#root'),
)

/** GRID Helper */
if (
  window.location.href.indexOf('localhost') !== -1 ||
  window.location.href.indexOf('qunabu') !== -1
) {
  /* eslint-disable  */
  // const config = require('sass-extract-loader!./../scss/common/_bootstrap-config.scss');
  // const grid = new Grid(config);
  /* eslint-enable  */
  // grid.attach();
}
