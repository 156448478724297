import * as ACTION from './actions'

export const INIT_STATE = {
  loading: false,
  error: false,
  data: [],
}

export default function reducer(state = INIT_STATE, action) {
  if (state === undefined) {
    return INIT_STATE
  }
  switch (action.type) {
    // FETCH -- start
    case ACTION.FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case ACTION.FETCH_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: action.payload.data,
      }

    case ACTION.FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // FETCH -- end

    default:
      return state
  }
}
