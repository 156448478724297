import { call, takeLatest, put } from 'redux-saga/effects'
import { createSaga } from '../sagas'
import API from '../../services/api'
import * as ACTION from './actions'

function* titles(action) {
  try {
    const response = yield call(API.call, `tags/titles`, 'GET', action.data)
    const responseBody = yield call([response, 'json'])

    if (response.status >= 400) {
      yield put({ type: ACTION.FETCH_TITLES_FAILURE, payload: responseBody })
    } else {
      yield put({ type: ACTION.FETCH_TITLES_SUCCESS, payload: responseBody })
    }
  } catch (e) {
    yield put({ type: ACTION.FETCH_TITLES_FAILURE, message: e.message })
  }
}

export default function* mySaga() {
  yield takeLatest(ACTION.FETCH_TITLES_REQUEST, titles)
  yield call(createSaga('TAGS', 'tags'))
}
