import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { createEventBusMiddleware, EventBus } from 'redux-event-bus'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import reducers from './reducers'
import rootSaga from './sagas'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router', 'changes', 'files', 'grouped_files', 'menu'],
}

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const eventBus = new EventBus()
const eventBusMiddleware = createEventBusMiddleware(eventBus)
const middlewares = [sagaMiddleware, routeMiddleware, eventBusMiddleware]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const appReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
})

const rootReducer = (state, action) => {
  // resets store to initial values after user logout
  if (action.type === 'USER_LOGOUT')
    state = { backendSettings: state.backendSettings, menu: state.menu }

  return appReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)))

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
export { store, history, eventBus, persistor }
