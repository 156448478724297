import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

import { getBonds } from '../../services/bonds'

import './style.scss'

const { Option } = Select

class BondSelect extends Component {
  static propTypes = {
    bondKey: PropTypes.string.isRequired,
    forcedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    /** values passed in this array will be removed from list */
    exclude: PropTypes.array,
    /** Custom parameters added to request */
    customFilter: PropTypes.object,
    /** if set, allow only entities from list */
    whitelist: PropTypes.arrayOf(PropTypes.number),
    /** message if field is not set */
    required: PropTypes.string,
    isStatic: PropTypes.bool,
  }

  static defaultProps = {
    forcedValue: '',
    placeholder: 'Start typing to search',
    onChange: () => {},
    exclude: [],
    customFilter: {},
    whitelist: [],
    isStatic: false,
    required: 'Start typing to search',
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      options: props.options || [],
      query: '*',
      value: props.forcedValue || undefined,
      touched: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.query !== this.state.query) {
      this.setSelectOptions(this.state.query, this.props.bondKey)
    } else if (prevProps.bondKey !== this.props.bondKey) {
      this.setState({ options: [] })
    } else if (prevProps.options !== this.props.options) {
      this.setState({ options: this.props.options })
    }
    if (prevProps.forcedValue !== this.props.forcedValue) {
      this.setState({ value: this.props.forcedValue })
    }
    if (prevProps.bondKey !== this.props.bondKey) {
      this.setState({ value: undefined })
    }
  }

  setSelectOptions = (query, key) => {
    if (query && key) {
      this.setState({ loading: true })

      const { customFilter } = this.props

      getBonds(key, query, { customFilter })
        .then((data) => {
          if (data && query === this.state.query) {
            this.setState((prevState) => ({
              ...prevState,
              loading: false,
              options: data.data,
              value: query === '*' ? this.props.forcedValue : prevState.value,
            }))
          }
        })
        .catch((er) => console.log(er))
    }
  }

  handleChange = (val) => {
    this.setState({ value: val })
    const selectedObject = this.state.options.find((o) => o.id === val) || {}
    this.props.onChange({
      model: this.props.bondKey,
      id: val,
      name: selectedObject.name,
    })
  }

  handleSearch = (query) => {
    this.setState({ query })
  }

  render() {
    return (
      <div className="select-bond">
        <Select
          className={this.props.className}
          showSearch
          onSearch={this.handleSearch}
          loading={this.state.loading}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          notFoundContent={null}
          onChange={this.handleChange}
          onBlur={() => this.setState({ touched: true })}
          value={this.state.value}
          placeholder={this.props.placeholder}
          disabled={this.props.isStatic}
        >
          {this.state.options
            .filter((o) => typeof this.props.exclude.find((id) => id === o.id) === 'undefined')
            .filter((o) =>
              !this.props.whitelist.length
                ? true
                : typeof this.props.whitelist.find((id) => id === o.id) !== 'undefined',
            )
            .map((o) => (
              <Option key={o.id} value={o.id}>
                {o.name}
              </Option>
            ))}
        </Select>
        <span className="select-bond__error">
          {this.state.value || !this.state.touched ? '' : this.props.required}
        </span>
      </div>
    )
  }
}

export default BondSelect
