import { call, put, takeEvery, all } from 'redux-saga/effects'
import { createSaga } from './sagas'
import API from '../services/api'

export function createSagaAPICall(prefix, endpoint, method = 'GET') {
  function* oneCallGenerator(action) {
    const data = Object.assign({}, action)
    const url = `${endpoint}${action.id ? `/${action.id}` : ''}`
    try {
      const response = yield call(API.call, url, method, action.data)
      const responseBody = yield call([response, 'json'])
      if (response.status >= 400) {
        yield put({
          type: `${prefix}_FAILURE`,
          data,
          payload: responseBody,
        })
      } else {
        yield put({
          type: `${prefix}_SUCCESS`,
          data,
          payload: responseBody,
        })
      }
    } catch (e) {
      // console.error('err', e)
      yield put({
        type: `${prefix}_FAILURE`,
        data,
        message: e.message,
      })
    }
  }

  return function* oneCallSaga() {
    yield takeEvery(`${prefix}_REQUEST`, oneCallGenerator)
    // yield all([takeEvery(`${prefix}_REQUEST`, oneCall())])
  }
}

export function generate(name, endpoint, settings = {}) {
  const defaultSettings = {
    paginated: true,
    notifications: true,
    reducerActions: {},
    sagas: [],
    initState: {},
  }

  settings = Object.assign({}, defaultSettings, settings)

  /** generate ACTION NAMES */
  const ACTIONS = {
    FETCH_REQUEST: `${name}_FETCH_REQUEST`,
    FETCH_SUCCESS: `${name}_FETCH_SUCCESS`,
    FETCH_FAILURE: `${name}_FETCH_FAILURE`,
    ADD_REQUEST: `${name}_ADD_REQUEST`,
    ADD_RESET: `${name}_ADD_RESET`,
    ADD_SUCCESS: `${name}_ADD_SUCCESS`,
    ADD_FAILURE: `${name}_ADD_FAILURE`,
    GET_REQUEST: `${name}_GET_REQUEST`,
    GET_SUCCESS: `${name}_GET_SUCCESS`,
    GET_FAILURE: `${name}_GET_FAILURE`,
    EDIT_REQUEST: `${name}_EDIT_REQUEST`,
    EDIT_SUCCESS: `${name}_EDIT_SUCCESS`,
    EDIT_FAILURE: `${name}_EDIT_FAILURE`,
    UPDATE_REQUEST: `${name}_UPDATE_REQUEST`,
    UPDATE_SUCCESS: `${name}_UPDATE_SUCCESS`,
    UPDATE_FAILURE: `${name}_UPDATE_FAILURE`,
    REMOVE_REQUEST: `${name}_REMOVE_REQUEST`,
    REMOVE_SUCCESS: `${name}_REMOVE_SUCCESS`,
    REMOVE_FAILURE: `${name}_REMOVE_FAILURE`,
  }

  /** generate ACTION functions */
  const fetch = (query = {}) => {
    return {
      type: ACTIONS.FETCH_REQUEST,
      query,
    }
  }

  const get = (id) => {
    return {
      type: ACTIONS.GET_REQUEST,
      id,
    }
  }

  const add = (data) => {
    return {
      type: ACTIONS.ADD_REQUEST,
      data,
    }
  }

  const edit = (id, data) => {
    return {
      type: ACTIONS.EDIT_REQUEST,
      id,
      data,
    }
  }

  const update = (id, data) => {
    return {
      type: ACTIONS.UPDATE_REQUEST,
      id,
      data,
    }
  }

  const remove = (id) => {
    return {
      type: ACTIONS.REMOVE_REQUEST,
      id,
    }
  }

  /** generate reducer */
  const INIT_STATE = Object.assign(
    {
      loading: false,
      error: false,
      /*
      paginatedData: {
        current_page: 0,
        last_page: 0,
        per_page: 10,
        to: 0,
        total: 0,
        data: [],
      },
      data: [],
      */
      byId: {},
      errorsById: {},
      byClassbyId: {},
      newId: null,
      [settings.paginated ? 'paginatedData' : 'data']: settings.paginated
        ? {
            current_page: 0,
            last_page: 0,
            per_page: 10,
            to: 0,
            total: 0,
            data: [],
          }
        : [],
    },
    settings.initState,
  )

  const reducer = (state = INIT_STATE, action) => {
    let newState
    let newState2
    if (state === undefined) {
      return INIT_STATE
    }

    if (settings.reducerActions[action.type]) {
      return settings.reducerActions[action.type](state, action)
    }

    switch (action.type) {
      // FETCH -- start
      case ACTIONS.FETCH_REQUEST:
        return {
          ...state,
          loading: true,
          error: false,
        }

      case ACTIONS.FETCH_SUCCESS:
        return {
          ...state,
          error: false,
          loading: false,
          [settings.paginated ? 'paginatedData' : 'data']: action.payload.data,
        }

      case ACTIONS.FETCH_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        }

      // FETCH -- end

      // GET -- start
      case ACTIONS.GET_REQUEST:
        return {
          ...state,
          loading: true,
          error: false,
          byId: {
            ...state.byId,
            [action.id]: state.byId[action.id]
              ? { ...state.byId[action.id], loading: true, error: false }
              : { loading: true, error: false },
          },
        }

      case ACTIONS.GET_SUCCESS:
        return {
          ...state,
          error: false,
          loading: false,
          byId: {
            ...state.byId,
            [action.payload.data.id]: {
              ...state.byId[action.payload.data.id],
              ...action.payload.data,
              loading: false,
              error: false,
            },
          },
        }

      case ACTIONS.GET_FAILURE:
        return {
          ...state,
          loading: false,
          byId: {
            ...state.byId,
            [action.data.id]: {
              ...state.byId[action.data.id],
              loading: false,
              error: action.payload?.data || action.payload?.message || true,
              code: action.statusCode || 404,
            },
          },
        }

      // GET -- end

      // UPDATE, ADD -- start
      case ACTIONS.ADD_REQUEST:
        return {
          ...state,
          loading: true,
          newId: null,
        }
      case ACTIONS.EDIT_REQUEST:
      case ACTIONS.UPDATE_REQUEST:
        return {
          ...state,
          loading: true,
        }

      case ACTIONS.ADD_SUCCESS:
      case ACTIONS.UPDATE_SUCCESS:
      case ACTIONS.EDIT_SUCCESS:
        return {
          ...state,
          errorsById: {}, // there should be only one error at once, so once succesful errors are cleared
          byId: {
            ...state.byId,
            [action.payload.data.id]: action.payload.data,
          },
          [settings.paginated ? 'paginatedData' : 'data']: settings.paginated
            ? {
                ...state.paginatedData,
                data: state.paginatedData.data.map((row) =>
                  row.id === action.payload.data.id ? action.payload.data : row,
                ),
              }
            : [
                ...state.data.map((row) =>
                  row.id === action.payload.data.id ? action.payload.data : row,
                ),
                action.payload.data,
              ].filter((item, key, array) => array.indexOf(item) === key),

          loading: false,
          newId: action.type === ACTIONS.ADD_SUCCESS ? action.payload.data.id : null,
        }

      case ACTIONS.ADD_FAILURE:
      case ACTIONS.EDIT_FAILURE:
      case ACTIONS.UPDATE_FAILURE:
        return {
          ...state,
          errorsById: {
            [action.type === ACTIONS.ADD_FAILURE ? 'new' : action.data.id]: action.payload
              ? action.payload.errors
              : action.message,
          },
          loading: false,
        }
      // UPDATE, ADD -- end

      // DELETE --start

      case ACTIONS.REMOVE_REQUEST:
        return {
          ...state,
          loading: true,
        }

      case ACTIONS.REMOVE_SUCCESS: {
        newState = Object.assign({}, state.byId)
        delete newState[action.data.id]

        newState2 = Object.assign({}, state.byClassbyId)

        Object.keys(newState2).forEach((classType) => {
          Object.keys(newState2[classType]).forEach((classId) => {
            newState2[classType][classId] = newState2[classType][classId].filter(
              (item) => item.id !== action.data.id,
            )
          })
        })

        return {
          ...state,
          byId: newState,
          [settings.paginated ? 'paginatedData' : 'data']: settings.paginated
            ? {
                ...state.paginatedData,
                data: state.paginatedData.data.filter((item) => item.id !== action.data.id),
              }
            : state.data.filter((item) => item.id !== action.data.id),
          byClassbyId: newState2,
          loading: false,
        }
      }

      case ACTIONS.REMOVE_FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
        }

      // DELETE --end

      default:
        return state
    }
  }

  /** mering main saga with injected sagas */
  function* rootSaga() {
    yield all(
      [call(createSaga(name, endpoint, settings.notifications))].concat(
        settings.sagas.map((saga) => call(saga)),
      ),
    )
  }

  // return everthing
  return {
    actions: {
      ...ACTIONS,
    },
    creators: {
      ...settings.creators,
      fetch,
      get,
      add,
      edit,
      update,
      remove,
    },
    reducer,
    saga: rootSaga,
    settings,
  }
}

export default {
  generate,
  createSagaAPICall,
}
