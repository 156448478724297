import { generate } from '../generator'

const all = generate('REPORT', 'reports')
const { saga, reducer, actions, creators } = all

export { saga, reducer, actions, creators }

export default {
  saga: all.saga,
  reducer: all.reducer,
  actions: all.actionNames,
  creators: all.action,
}
