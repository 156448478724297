import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { login, currentAccount } from '../../services/user'
import { notifyError } from '../sagas'
import { LOGIN, LOAD_CURRENT_ACCOUNT, LOGOUT, setState, loadCurrentAccount } from './actions'
import API from '../../services/api'

export function* loginSaga({ payload }) {
  const { email, password } = payload
  yield put(setState({ loading: true }))
  const response = yield call(login, email, password)
  if (response.success) {
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in to LW Databases!',
    })
    yield put(setState({ token: response.token }))
    yield put(loadCurrentAccount())
  } else {
    notifyError('FAILURE', { ...response, description: response.message, message: 'Login failed' })
  }
  yield put(setState({ loading: false }))
}

export function* loadCurrentAccountSaga() {
  yield put(
    setState({
      loading: true,
    }),
  )
  const response = yield call(currentAccount)
  if (response) {
    yield put(setState({ ...response }))
  }
  yield put(setState({ loading: false }))
}

export function* logoutSaga() {
  API.token = ''
  yield put(
    setState({
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      loading: false,
      token: '',
    }),
  )
}

export default function* rootSaga() {
  yield all([
    takeEvery(LOGIN, loginSaga),
    takeEvery(LOAD_CURRENT_ACCOUNT, loadCurrentAccountSaga),
    takeEvery(LOGOUT, logoutSaga),
  ])
}
