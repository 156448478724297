import React from 'react'
import { Layout } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styles from './style.module.scss'
import { getVersion } from '../../utils/utils'

const FOOTER_TEXT = `${new Date().getFullYear()} | All rights reserved. | ${getVersion()}`

@withRouter
@connect(({ backendSettings }) => ({ backendSettings }))
class LoginLayout extends React.PureComponent {
  render() {
    const {
      children,
      backendSettings: { name },
    } = this.props

    return (
      <Layout>
        <Layout.Content>
          <div className={styles.layout}>
            <div className={styles.header}>
              <div className={styles.logo}>
                <Link to="/">
                  <span>{name}</span>
                </Link>
              </div>
              <nav className={styles.navigation}>
                <ul className={styles.navigationItems}>
                  {/* <li>
                    <a className={styles.navigationActive} href="#!anchor">
                      Login
                    </a>
                  </li> */}
                </ul>
              </nav>
            </div>
            <div className={styles.content}>{children}</div>
            <div className={`${styles.footer} text-center`}>
              {/* <ul className="list-unstyled list-inline mb-3">
                <li className="list-inline-item">
                  <a href="http://google.com">Terms of Use</a>
                </li>
                <li className="active list-inline-item">
                  <a href="#!anchor">Compliance</a>
                </li>
                <li className="list-inline-item">
                  <a href="#!anchor">Confidential Information</a>
                </li>
                <li className="list-inline-item">
                  <a href="#!anchor">Support</a>
                </li>
                <li className="list-inline-item">
                  <a href="#!anchor">Contacts</a>
                </li>
              </ul> */}
              <p>&copy; {FOOTER_TEXT}</p>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default LoginLayout
