import { call, put, takeLatest } from 'redux-saga/effects'
import API from '../../services/api'
import * as ACTION from './actions'

function* fetch() {
  try {
    const response = yield call(API.call, `settings`)
    const responseBody = yield call([response, 'json'])

    if (response.status >= 400) {
      yield put({ type: ACTION.FETCH_FAILURE, payload: responseBody })
    } else {
      yield put({ type: ACTION.FETCH_SUCCESS, payload: responseBody, code: response.status })
    }
  } catch (e) {
    yield put({ type: ACTION.FETCH_FAILURE, message: e.message })
  }
}

function* mySaga() {
  yield takeLatest(ACTION.FETCH_REQUEST, fetch)
}

export default mySaga
