import { generate } from '../generator'

const reducerActions = {
  GROUPED_FILES_FETCH_SUCCESS: (state, action) => {
    const newState = {}
    const newState2 = {}
    action.payload.data.forEach((file) => {
      newState[file.class_type] = newState[file.class_type] || {}
      newState[file.class_type][file.class_id] = newState[file.class_type][file.class_id] || []
      newState[file.class_type][file.class_id][file.id] = file
      newState2[file.id] = file
    })
    return {
      ...state,
      loading: false,
      error: false,
      byClassbyId: newState,
      byId: newState2,
    }
  },
  GROUPED_FILES_ADD_SUCCESS: (state, action) => {
    const newState = Object.assign({}, state.byClassbyId)
    const newState2 = Object.assign({}, state.byId)
    const file = action.payload.data
    newState[file.class_type] = newState[file.class_type] || {}
    newState[file.class_type][file.class_id] = newState[file.class_type][file.class_id] || []
    newState[file.class_type][file.class_id][file.id] = file
    newState2[file.id] = file
    return {
      ...state,
      loading: false,
      error: false,
      byClassbyId: newState,
      byId: newState2,
    }
  },
  GROUPED_FILES_EDIT_SUCCESS: (state, action) => {
    const newState = Object.assign({}, state.byClassbyId)
    const newState2 = Object.assign({}, state.byId)
    const file = action.payload.data
    newState[file.class_type][file.class_id][file.id] = file
    newState2[file.id] = file
    return {
      ...state,
      loading: false,
      error: false,
      byClassbyId: newState,
      byId: newState2,
    }
  },
  GROUPED_FILES_REMOVER_SUCCESS: (state, action) => {
    const newState = Object.assign({}, state.byClassbyId)
    const newState2 = Object.assign({}, state.byId)
    delete newState2[action.payload.data.id]

    Object.keys(newState.byClassbyId).forEach((modelKey) => {
      Object.keys(newState.byClassbyId[modelKey]).forEach((modelId) => {
        newState.byClassbyId[modelKey][modelId] = newState.byClassbyId[modelKey][modelId].filter(
          (file) => file.id !== action.payload.data.id,
        )
      })
    })

    return {
      ...state,
      loading: false,
      error: false,
      byClassbyId: newState,
      byId: newState2,
    }
  },
}

const all = generate('GROUPED_FILES', 'grouped_files', {
  notifications: false,
  reducerActions,
})
const { saga, reducer, actions, creators } = all

export { saga, reducer, actions, creators }

export default {
  saga: all.saga,
  reducer: all.reducer,
  actions: all.actionNames,
  creators: all.action,
}
