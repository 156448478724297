import { generate, createSagaAPICall } from '../generator'

export const ACTIONS = {
  USER_LOCK_REQUEST: 'USER_LOCK_REQUEST',
  USER_LOCK_SUCCESS: 'USER_LOCK_SUCCESS',
  USER_LOCK_FAILURE: 'USER_LOCK_FAILURE',
  USER_ROLES_FETCH_REQUEST: 'USER_ROLES_FETCH_REQUEST',
  USER_ROLES_FETCH_SUCCESS: 'USER_ROLES_FETCH_SUCCESS',
  USER_ROLES_FETCH_FAILURE: 'USER_ROLES_FETCH_FAILURE',
}

const reducerActions = {
  [ACTIONS.USER_LOCK_SUCCESS]: (state, action) => {
    const newState = { ...state }
    delete newState.byId[action.data.id]
    newState.paginatedData.data = newState.paginatedData.data.filter(
      ({ id }) => id !== parseInt(action.data.id, 10),
    )
    return newState
  },
  [ACTIONS.USER_ROLES_FETCH_SUCCESS]: (state, action) => {
    return {
      ...state,
      roles: action.payload.data,
    }
  },
  [ACTIONS.USER_ROLES_FETCH_REQUEST]: (state) => state,
  [ACTIONS.USER_ROLES_FETCH_FAILURE]: (state) => state,
}

const restCreators = {
  fetchRoles: () => {
    return {
      type: ACTIONS.USER_ROLES_FETCH_REQUEST,
    }
  },
  lock: (id) => ({
    type: ACTIONS.USER_LOCK_REQUEST,
    id,
  }),
}

const all = generate('USERS', 'users', {
  reducerActions,
  creators: restCreators,
  sagas: [
    createSagaAPICall('USER_ROLES_FETCH', 'roles'),
    createSagaAPICall('USER_LOCK', 'users/lock', 'GET'),
  ],
})

const { saga, reducer, actions, creators: tempCreators } = all
const creators = { ...tempCreators, ...restCreators }

export { saga, reducer, actions, creators }

export default {
  saga: all.saga,
  reducer: all.reducer,
  actions: Object.assign({}, all.actionNames, ACTIONS),
  creators: all.action,
}
