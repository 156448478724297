import API from './api'

export const login = async (email, password) => {
  const response = await API.call('login', 'POST', { email, password })
  const body = await response.json()

  if (body.token) {
    // Login successfull
    API.token = body.token
    return Promise.resolve({ success: true, token: body.token })
  }

  return Promise.resolve(body)
}

export const currentAccount = async () => {
  const response = await API.call('me')
  const body = await response.json()

  if (response.status === 200) {
    return Promise.resolve({
      ...body.user,
    })
  }

  return Promise.resolve({ token: '' })
}

export const register = async () => {
  // [POST] /register
}
