import { useContext } from 'react'
import { FormContext } from './context'

export default function useFormContext() {
  const form = useContext(FormContext)
  if (!form) {
    throw new Error('Missing FormContextProvider in its parent.')
  }
  return form
}
