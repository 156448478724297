/* eslint-disable */

let debug = false

switch (window.location.host) {
  case 'lw.qunabu.com':
  case 'lw-live.qunabu.com':
    debug = true
    break
  default:
    debug = false
    break
}

if (debug) {
  //<!-- Ybug code -->
  ;(function() {
    window.ybug_settings = { id: 'wj0q47gztf' }
    var ybug = document.createElement('script')
    ybug.type = 'text/javascript'
    ybug.async = true
    ybug.src = 'https://ybug.io/api/v1/button/' + window.ybug_settings.id + '.js'
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(ybug, s)
  })()
  //<!-- Ybug code end -->
}
