export const FETCH_REQUEST = 'EMAIL_FETCH_REQUEST'
export const FETCH_SUCCESS = 'EMAIL_FETCH_SUCCESS'
export const FETCH_FAILURE = 'EMAIL_FETCH_FAILURE'

export const GET_REQUEST = 'EMAIL_GET_REQUEST'
export const GET_SUCCESS = 'EMAIL_GET_SUCCESS'
export const GET_FAILURE = 'EMAIL_GET_FAILURE'

export const fetch = (query = {}) => {
  return {
    type: FETCH_REQUEST,
    query,
  }
}

export const get = (id) => {
  return {
    type: GET_REQUEST,
    id,
  }
}
